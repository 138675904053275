import { NextPage } from 'next'

import LoginCom from '@/components/Login'

const Login: NextPage = () => {
  return (
    <div className="pt-40">
      <LoginCom />
    </div>
  )
}

export default Login
